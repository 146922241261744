<template>
    <div>

        <div class="horizontalMenucontainer">
            <myHeader />
            <myMenu />
            <div class="app-content panel">
                <div class="container">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <router-view :key="$route.fullPath"></router-view>
                </div>
            </div>
            <myFooter />
        </div>
    </div>
</template>

<script>
import '../../assets/css/style-rtl.css'
import '../../assets/plugins/horizontal-menu/dropdown-effects/fade-up.css'
import '../../assets/css/default-rtl.css'
import '../../assets/css/icons.css'
import '../../assets/plugins/horizontal-menu/horizontal-rtl.css'
import '../../assets/css/skins.css'
    import myFooter from './shared/footer'
    import myHeader from './shared/header'
    import myMenu from './shared/menuBar'

    export default {

        name: 'App',
        components: {
            myFooter,
            myHeader,
            myMenu
        },
        mounted() {
            // if (this.$root.getCookie("adminAuthorization")) {
            //     this.$axios.defaults.headers.common["Authorization"] = this.$root.getCookie(
            //         "adminAuthorization"
            //     );
            // }
            // this.$root.getAdmin()
        }
    }
</script>