<template>
  <div class="horizontal-main hor-menu clearfix">
    <div class="horizontal-mainwrapper container clearfix">
      <nav class="horizontalMenu clearfix" >
        <ul class="horizontalMenu-list">
          <li aria-haspopup="true">
            <router-link to="/panel" 
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon_img"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 365.001 365.001"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path
                        d="M360.74,155.711l-170-149c-4.717-4.133-11.764-4.133-16.48,0l-170,149c-5.191,4.55-5.711,12.448-1.161,17.641    c4.55,5.19,12.449,5.711,17.64,1.16l13.163-11.536V348.89c0,6.903,5.596,12.5,12.5,12.5h94.733h82.73h94.732   c6.904,0,12.5-5.597,12.5-12.5V162.977l13.163,11.537c2.373,2.078,5.311,3.1,8.234,3.1c3.476,0,6.934-1.441,9.405-4.261    C366.452,168.159,365.932,160.262,360.74,155.711z M153.635,336.39V233.418h57.729v102.973L153.635,336.39L153.635,336.39z     M306.099,141.161V336.39h-69.732V220.918c0-6.903-5.598-12.5-12.5-12.5h-82.73c-6.903,0-12.5,5.597-12.5,12.5v115.473H58.903    V141.161c0-0.032-0.004-0.062-0.004-0.093L182.5,32.733l123.603,108.334C306.104,141.1,306.099,141.129,306.099,141.161z"
                        data-original="#000000"
                        class="active-path"
                        data-old_color="#000000"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M154.5,120.738c0,6.904,5.596,12.5,12.5,12.5h31c6.903,0,12.5-5.596,12.5-12.5s-5.597-12.5-12.5-12.5h-31    C160.097,108.238,154.5,113.834,154.5,120.738z"
                        data-original="#000000"
                        class="active-path"
                        data-old_color="#000000"
                        fill="#FFFFFF"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              صفحه اصلی </router-link>
          </li>
        <li aria-haspopup="true">
            <router-link to="/panel/products">
                 کالاها
            </router-link>
        </li>
        <li aria-haspopup="true">
            <router-link to="/panel/provides">
                 سفارشات
            </router-link>
        </li>
        <li aria-haspopup="true">
            <router-link to="/panel/payment">
                 مالی
            </router-link>
        </li>
        <li aria-haspopup="true">
            <router-link to="/panel/profile">
                 پروفایل
            </router-link>
        </li>
        <li aria-haspopup="true">
            <router-link to="/panel/tickets">
                 تیکت
            </router-link>
        </li>
        <!-- <li aria-haspopup="true">
            <a  class="sub-icon">
                 گزارشات
                <i class="fa fa-angle-down horizontal-icon"></i>
            </a>
            <ul class="sub-menu">
                <li>
                    <router-link to="/panel/logs/sales" class="slide-item">فروش </router-link>
                </li>
                <li>
                    <router-link to="/panel/logs/products" class="slide-item">کالاها</router-link>
                </li>
            </ul>
        </li> -->
        </ul>
      </nav>
      <!--Nav end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings: [],
    };
  },
  mounted() {
    window.$('#horizontal-navtoggle').click(function() {
			window.$('body').toggleClass('active');
		});
    window.$('.horizontalMenu > .horizontalMenu-list > li').has('.sub-menu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		window.$('.horizontalMenu > .horizontalMenu-list > li').has('.horizontal-megamenu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		window.$('.horizontalMenu-click').click(function() {
			window.$(this).toggleClass('ws-activearrow').parent().siblings().children().removeClass('ws-activearrow');
			window.$(".horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu").not(window.$(this).siblings('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu')).slideUp('slow');
			window.$(this).siblings('.sub-menu').slideToggle('slow');
			window.$(this).siblings('.horizontal-megamenu').slideToggle('slow');
		});
		window.$('.horizontalMenu > .horizontalMenu-list > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		window.$('.horizontalMenu > .horizontalMenu-list > li > ul > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span>');
		window.$('.horizontalMenu-click02').click(function() {
			window.$(this).children('.horizontalMenu-arrow').toggleClass('horizontalMenu-rotate');
			window.$(this).siblings('li > .sub-menu').slideToggle('slow');
		});
		window.$(window).on('resize', function() {
			if (window.$(window).outerWidth() < 992) {
				window.$('.horizontalMenu').css('height', window.$(this).height() + "px");
				window.$('.horizontalMenucontainer').css('min-width', window.$(this).width() + "px");
			} else {
				window.$('.horizontalMenu').removeAttr("style");
				window.$('.horizontalMenucontainer').removeAttr("style");
				window.$('body').removeClass("active");
				window.$('.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu').removeAttr("style");
				window.$('.horizontalMenu-click').removeClass("ws-activearrow");
				window.$('.horizontalMenu-click02 > i').removeClass("horizontalMenu-rotate");
			}
		});
    window.$(window).trigger('resize');

    if (this.$root.getCookie("adminAuthorization")) {
      this.$axios.defaults.headers.common[
        "Authorization"
      ] = this.$root.getCookie("adminAuthorization");
    }

  },
  created(){
    // this.$root.havePermission(4) ? this.loadSetting() : ''
  },
  methods: {
    loadSetting() {
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/settings")
        .then((response) => {
          this.settings = response.data.data;
        });
    },
  },
};
</script>
<style scoped>
  .sub-menu a {
      color: #3c4858  !important;
}
.container {
  max-width: 1200px;
}
.horizontal-main {
  margin-top: 65px;
}
.hor-menu .horizontalMenu > .horizontalMenu-list > li > a{
  padding: 1rem !important;
}
.sub-menu-sub{
  color: #007bff;
}
.sub-menu-sub:hover{
  color: #2205bf;
}
</style>
